import {
	Icon56LockOpenOutline,
} from '@vkontakte/icons';
import { useActiveVkuiLocation, /*useEnableSwipeBack,*/ useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Button,
	Panel,
	PanelHeader,
	Placeholder,
	View,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React from 'react';

import SearchBar from '../components/SearchBar';


const KeysView = ({ id }) => {
	//useEnableSwipeBack();
	const routeNavigator = useRouteNavigator();
	const { panel: activePanel, panelsHistory } = useActiveVkuiLocation();

	return (
		<View
			id={ id }
			activePanel={ activePanel }
			history={ panelsHistory }
			onSwipeBack={() => routeNavigator.back()}
		>
			<Panel id="keys">
				<PanelHeader separator={ false }>PhoneDooring</PanelHeader>

				<SearchBar/>

				<Placeholder stretched
					icon={ <Icon56LockOpenOutline/> }
					action={
						<Button
							size="m"
							mode="primary"
							onClick={() => routeNavigator.replace(`/`)}
						>
							Найти квартиру
						</Button>
					}
				>
					Здесь будут отображаться ключи от забронированных вами квартир, а также ключи, которыми с вами поделились
				</Placeholder>
			</Panel>
		</View>
	);
};

KeysView.propTypes = {
	id: PropTypes.string.isRequired,
};

export default KeysView;


