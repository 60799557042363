import {
	Icon28DocumentTextOutline,
	Icon28KeySquareOutline,
	Icon28MessageOutline,
	Icon28Notifications,
	Icon28UserOutline,
	Icon28UserStarBadgeOutline,
} from '@vkontakte/icons';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Alert,
	Avatar,
	Group,
	Panel,
	PanelHeader,
	SimpleCell,
	//calcInitialsAvatarColor,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React from 'react';


const Profile = ({ id, fetchedUser }) => {
	const routeNavigator = useRouteNavigator();

	return (
		<Panel id={ id }>
			<PanelHeader separator={ false }>Профиль</PanelHeader>

			{ fetchedUser && (
				<Group>
					<SimpleCell disabled
						before={ fetchedUser.photo_200?<Avatar
							src={ fetchedUser.photo_200 }
							size={ 72 }
							//gradientColor={ calcInitialsAvatarColor(fetchedUser.id) }  // тут есть баг: градиент выглядывает из-за края фото
							initials={ fetchedUser.first_name[0] + fetchedUser.last_name[0] }
						/>:null }
						subtitle="Аккаунт не верифицирован"
					>
						{ `${fetchedUser.first_name} ${fetchedUser.last_name}` }
					</SimpleCell>
				</Group>
			) }

			<Group>
				<SimpleCell expandable
					before={ <Icon28UserOutline/> }
					onClick={() => routeNavigator.push(`./account`)}
				>
					Аккаунт
				</SimpleCell>

				<SimpleCell expandable
					before={ <Icon28Notifications/> }
					onClick={() => routeNavigator.push(`./notifications`)}
				>
					Уведомления
				</SimpleCell>

				<SimpleCell expandable
					before={ <Icon28MessageOutline/> }
					onClick={() => routeNavigator.push(`./support`)}
				>
					Поддержка
				</SimpleCell>

				<SimpleCell expandable
					before={ <Icon28DocumentTextOutline/> }
					onClick={() => routeNavigator.showPopout(<Alert
						header="Функция пока недоступна."
						actions={ [{title: "Ок", autoClose: true}] }
						dismissLabel="Ок"
						onClose={() => routeNavigator.hidePopout()}
					/>) }
				>
					Документы
				</SimpleCell>

				<SimpleCell expandable
					before={ <Icon28KeySquareOutline/> }
					onClick={() => routeNavigator.showPopout(<Alert
						header="Функция пока недоступна."
						actions={ [{title: "Ок", autoClose: true}] }
						dismissLabel="Ок"
						onClose={() => routeNavigator.hidePopout()}
					/>) }
				>
					Архив заказов
				</SimpleCell>

				<SimpleCell expandable
					before={ <Icon28UserStarBadgeOutline/> }
					onClick={() => routeNavigator.showPopout(<Alert
						header="Функция пока недоступна."
						actions={ [{title: "Ок", autoClose: true}] }
						dismissLabel="Ок"
						onClose={() => routeNavigator.hidePopout()}
					/>) }
				>
					Бонусы
				</SimpleCell>
			</Group>
		</Panel>
	);
};

Profile.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		photo_200: PropTypes.string,
	}),
};

export default Profile;
