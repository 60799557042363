import { useActiveVkuiLocation, /*useEnableSwipeBack,*/ useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	View,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React from 'react';

import Account from '../profile/Account';
import Notifications from '../profile/Notifications';
import Profile from '../profile/Profile';
import Support from '../profile/Support';


const MenuView = ({ id, fetchedUser }) => {
	//useEnableSwipeBack();
	const routeNavigator = useRouteNavigator();
	const { panel: activePanel, panelsHistory } = useActiveVkuiLocation();

	return (
		<View
			id={ id }
			activePanel={ activePanel }
			history={ panelsHistory }
			onSwipeBack={() => routeNavigator.back()}
		>
			<Profile
				id="profile"
				fetchedUser={ fetchedUser }
			/>

			<Account
				id="account"
				fetchedUser={ fetchedUser }
			/>

			<Notifications id="notifications"/>

			<Support id="support"/>
		</View>
	);
};

MenuView.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
		photo_200: PropTypes.string,
	}),
};

export default MenuView;

