import bridge from '@vkontakte/vk-bridge';

import { default as FLATS } from './flats.json';


export { FLATS };

export const SUBWAY_LINE_COLORS = {
	"Санкт-Петербург": {
		'1': '#d6083b',
		'2': '#0078c9',
		'3': '#009a49',
		'4': '#ea7125',
		'5': '#702785',
		'6': '#8d5b2d',
		'7': '#999999',
		'8': '#85d4f3',
	},
};

export const getFlat = (flatId) => {
	return FLATS.find(({ id }) => id == flatId);
};

export const getFlatReviews = async (flatId) => {
	const res = await fetch(`/static/reviews/${flatId}/reviews.json`);
	if (!res.ok) throw new Error(res.statusText);

	return await res.json();
};

export const getFavorites = async () => {
	const data = await bridge.send('VKWebAppStorageGet', {
		keys: ['favorites'],
	});

	let { favorites } = data.keys.reduce((res, { key, value }) => (res[key] = value, res), {});
	if (favorites) favorites = JSON.parse(favorites);

	return (favorites instanceof Array)?favorites:[];
};

export const saveFavorites = async (favorites) => {
	return await bridge.send('VKWebAppStorageSet', {
		key: 'favorites',
		value: JSON.stringify(favorites),
	});
};

export const addToFavorites = async (id) => {
	let favorites = await getFavorites();
	if (favorites.includes(id)) return true;

	favorites.unshift(id);

	const { result } = await saveFavorites(favorites);
	return result;
};

export const removeFromFavorites = async (id) => {
	let favorites = await getFavorites();
	if (!favorites.includes(id)) return true;

	favorites = favorites.filter((i) => (i != id));

	const { result } = await saveFavorites(favorites);
	return result;
};

/*export const FLATS = [
	{
		id: 1,
		status: 'active',

		daily_rate: 500,

		location: {
			address: {
				city: "Москва",
				street: "ул. Новый Арбат",
				house: 26,
			},
			transport: [
				{
					type: 'subway',
					name: "Смоленская",
					line: 2,
					eta: 8,
				},
			],
			latitude: 59.938732,
			longitude: 30.316229,
		},

		apartment_type: 'flat',
		floor: 10,
		story_count: 11,
		building_year: 2011,
		room_count: 1,
		area: 42,
		max_guests: 2,

		flags: {
			permissions: {
				childrenAllowed: true,
				petsAllowed: true,
				partiesAllowed: true,
			},
			facilities: {
				bath: true,
				fridge: true,
				kitchenSuite: true,
				washingMachine: true,
				wifi: true,
				tv: true,
				iron: true,
			},
			infrastructure: {
				disabledInfra: true,
				elevator: true,
				parking: true,
				garbageChute: true,
			},
			security: {
				security: true,
				barrier: true,
				cctv: true,
				concierge: true,
			},
		},

		description: `Квартира находится в самом центре Москвы с видом на Новый Арбат. Есть ванная комната с душевой кабиной`,
		url: "https://avito.ru/…",
		photos: [
			"https://cdn.apartmenttherapy.info/image/upload/v1619013756/at/house%20tours/2021-04/Erin%20K/KERR-130-CLARKSON-2R-01-020577-EDIT-WEB.jpg",
			"https://cdn.apartmenttherapy.info/image/upload/f_auto,q_auto:eco,w_730/at%2Fhouse%20tours%2F2021-03%2FMaria%20H%2FApartment_Therapy_-_Maria_-_March_12_202176480",
			"https://www.srijanrealty.com/wp-content/uploads/2022/07/3-bhk-flat-in-kolkata-1200x900.png",
			"https://img.staticmb.com/mbcontent/images/uploads/2022/7/difference-between-flat-and-apartment.jpg",
		],
	},
];*/
