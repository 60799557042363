import {
	Icon28LikeFillRed,
	Icon28LikeOutline,
	Icon28ShareOutline,
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import {
	Div,
	Gallery,
	IconButton,
	MiniInfoCell,
	SimpleCell,
	Spacing,
	Text,
	Title,
	useAppearance,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React from 'react';

import { SUBWAY_LINE_COLORS, addToFavorites, removeFromFavorites } from '../data';
import { ReactComponent as IconSubwaySpb } from '../icons/IconSubwaySpb.svg';


const FlatCard = ({
	data,
	favorite,
	fetchFavorites,
}) => {
	const appearance = useAppearance();

	return (
		<>
			<Div>
				<Gallery showArrows
					bullets={ (data.photos?.length > 1) && 'light' }
					style={ {borderRadius: 8} }
				>
					{ (data.photos?.length > 0) && data.photos.map((url, ii) => (
						<img
							key={ url }
							src={ url }
							style={ {display: 'block', objectFit: 'contain'} }
							onClick={(e) => {
								bridge.send('VKWebAppShowImages', {
									images: data.photos,
									start_index: ii,
								}).catch(console.error);

								e.stopPropagation();
							}}
						/>
					)) || (data.location.latitude && data.location.longitude) && (
						<img
							src={ `https://maps.vk.com/api/staticmap/png?width=512&height=256&scale=2&zoom=11&latlon=${data.location.latitude},${data.location.longitude}&pins=${data.location.latitude},${data.location.longitude},red_pin&style=${appearance==='light'?'main':'dark'}&api_key=a4b32239b9ecd9ab61fb974a8027e42747152f97166b1320860b2a10c7858332` }
							style={ {display: 'block'} }
						/>
					) }
				</Gallery>

				<Spacing size={ 16 }/>

				<SimpleCell disabled
					after={
						<>
							<IconButton
								aria-label="В избранном"
								appearance="accent"
								onClick={(e) => {
									if (!favorite)
										addToFavorites(data.id)
											.then((result) => (result) && fetchFavorites?.(null, +data.id))
											.catch(console.error);
									else
										removeFromFavorites(data.id)
											.then((result) => (result) && fetchFavorites?.(null, -data.id))
											.catch(console.error);

									e.stopPropagation();
								}}
							>
								{ favorite?<Icon28LikeFillRed/>:<Icon28LikeOutline/> }
							</IconButton>

							<IconButton
								aria-label="Поделиться"
								appearance="accent"
								onClick={(e) => {
									setTimeout(() => {
										bridge.send('VKWebAppShare', {
											link: `https://vk.com/app51732139#/flat/${data.id}`,
										}).catch(console.error);
									}, 150);

									e.stopPropagation();
								}}
							>
								<Icon28ShareOutline/>
							</IconButton>
						</>
					}
					style={ {paddingLeft: 0, paddingRight: 8} }
				>
					<Title>{ (data.rate.daily / 100).toLocaleString() } руб./сутки</Title>
				</SimpleCell>

				<Div style={ {paddingTop: 0, paddingLeft: 0} }>
					<Text weight="1">{ data.room_count>0?`${data.room_count}-комн. кв`:'студия' }&ensp;·&ensp;{ data.area } м²&ensp;·&ensp;{ data.floor }/{ data.story_count } этаж</Text>

					<Spacing/>

					<Text>{ (`${data.location.address.street}, ${data.location.address.house}, ${data.location.address.city}`).replaceAll('-', '-\u2060') }</Text>
				</Div>

				{ data.location.transport.map((i, ii) => (
					<MiniInfoCell
						key={ ii }
						before={ <IconSubwaySpb width={ 20 } height={ 16 } fill={ SUBWAY_LINE_COLORS[data.location.address.city][i.line] } style={ {marginTop: 2, marginBottom: -2} }/> }
						mode="accent"
						style={ {paddingTop: 0, paddingLeft: 0} }
					>
						{ i.name }&ensp;<span style={ {color: 'var(--vkui--color_text_secondary)'} }>{ i.eta } мин.</span>
					</MiniInfoCell>
				)) }
			</Div>
		</>
	);
};

FlatCard.propTypes = {
	data: PropTypes.object.isRequired,
	favorite: PropTypes.bool,
	fetchFavorites: PropTypes.func.isRequired,
};

export default FlatCard;
