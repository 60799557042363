import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	SimpleCell,
} from '@vkontakte/vkui';
import { useFirstPageCheck, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import PropTypes from 'prop-types';
import React from 'react';


const Support = ({ id }) => {
	const routeNavigator = useRouteNavigator();
	const isFirstPage = useFirstPageCheck();

	return (
		<Panel id={ id }>
			<PanelHeader
				before={
					<PanelHeaderBack
						label="Назад"
						onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
					/>
				}
			>
				Поддержка
			</PanelHeader>

			<Group>
				<SimpleCell expandable
					onClick={() => window.open("https://vk.com/call?id=801151321")}
				>
					Позвонить
				</SimpleCell>

				<SimpleCell expandable
					onClick={() => routeNavigator.push(`/support`)}
				>
					Сообщить о проблеме с квартирой
				</SimpleCell>
			</Group>
		</Panel>
	);
};

Support.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Support;
