import {
	Icon56ReportOutline,
} from '@vkontakte/icons';
import {
	Button,
	Panel,
	PanelHeader,
	Placeholder,
	View,
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import PropTypes from 'prop-types';
import React from 'react';


const UXRView = ({
	id,
	flat,
}) => {
	return (
		<View
			id={ id }
			activePanel="uxr"
		>
			<Panel id="uxr">
				<PanelHeader separator={ false }>
					PhoneDooring
				</PanelHeader>

				<Placeholder stretched
					icon={ <Icon56ReportOutline color="var(--vkui--color_icon_accent)"/> }
				>
					<p>Спасибо за&nbsp;проявленный интерес к&nbsp;новому&nbsp;сервису!</p>

					<p>На&nbsp;данный момент оплата ещё&nbsp;не&nbsp;доступна.</p>

					<p>Для завершения бронирования, пожалуйста, перейдите на&nbsp;другую платформу и&nbsp;свяжитесь с&nbsp;арендодателем по&nbsp;кнопке ниже</p>

					<br/>

					<Button
						appearance="accent"
						size="m"
						onClick={() => window.open(flat.url, '_blank')}
					>
						Связаться с арендодателем
					</Button>
				</Placeholder>
			</Panel>
		</View>
	);
};

UXRView.propTypes = {
	id: PropTypes.string.isRequired,
	flat: PropTypes.object.isRequired,
};

export default UXRView;
