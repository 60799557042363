import bridge from '@vkontakte/vk-bridge';
import { useAdaptivity, useAppearance, useInsets } from '@vkontakte/vk-bridge-react';
import {
	RouterProvider,
	RoutesConfig,
	createHashRouter,
	createModal,
	createPanel,
	createView,
} from '@vkontakte/vk-mini-apps-router';
import {
	AdaptivityProvider,
	AppRoot,
	ConfigProvider,
	Platform,
	usePlatform,
} from '@vkontakte/vkui';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';


export const routes = RoutesConfig.create([
	createView('search', [
		createPanel('map', `/`),
		createPanel('list', `/list`),
		createPanel('filters', `/filters`),
		createPanel('flat', `/flat/:flatId`, [], ['flatId']),
	]),

	createView('favorites', [
		createPanel('list', `/favorites`),
		createPanel('flat', `/favorites/flat/:flatId`, [], ['flatId']),
	]),

	createView('keys', [
		createPanel('keys', `/keys`),
	]),

	createView('support', [
		createPanel('support', `/support`),
		createPanel('support', `/support/flat/:flatId`, [], ['flatId']),
	]),

	createView('menu', [
		createPanel('profile', `/profile`),
		createPanel('account', `/profile/account`, [
			createModal('accountedit', `/profile/account/edit`),
		]),
		createPanel('notifications', `/profile/notifications`),
		createPanel('support', `/profile/support`),
	]),

	createView('uxr', [
		createPanel('uxr', `/uxr`),
	]),
]);

export const router = createHashRouter(routes.getRoutes());

const PhoneDooring = () => {
	const adaptivity = useAdaptivity();
	const appearance = useAppearance();
	const insets = useInsets();
	const platform = usePlatform();

	return (
		<ConfigProvider
			appearance={ appearance }
			isWebView={ bridge.isWebView() }
			hasCustomPanelHeaderAfter={ platform === Platform.IOS }
			hasCustomPanelHeaderAfterProp={ platform === Platform.IOS }
		>
			<AdaptivityProvider adaptivity={ adaptivity }>
				<AppRoot safeAreaInsets={ insets }>
					<RouterProvider router={ router }>
						<App/>
					</RouterProvider>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
};

bridge.send('VKWebAppInit');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<PhoneDooring/>);

/*if (process.env.NODE_ENV === 'development') {
	import('./eruda').then(({ default: eruda }) => {});  // eslint-disable-line no-unused-vars
}*/
