import {
	Icon20FreezeOutline,
	Icon24CakeOutline,
	Icon24ChefHatOutline,
	Icon24HorseToyOutline,
	Icon24PawOutline,
	Icon24TshirtOutline,
	Icon24Users3Outline,
	Icon24WasherOutline,
	Icon28TvOutline,
	Icon56WifiOutline,
} from '@vkontakte/icons';
import { useFirstPageCheck, useParams, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Avatar,
	Button,
	Div,
	Group,
	MiniInfoCell,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	PullToRefresh,
	RichCell,
	Spacing,
	Text,
	Title,
	useAppearance,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getFlat, getFlatReviews } from '../data';
import { ReactComponent as IconBath } from '../icons/IconBath.svg';
import FlatCard from '../components/FlatCard';


export const Flat = ({
	id,
	data,
	period, setPeriod,
	setUxrFlat,
	favorites, fetchFavorites,
	...props
}) => {
	const routeNavigator = useRouteNavigator();
	const isFirstPage = useFirstPageCheck();
	const { flatId } = useParams({ panel: id });

	const appearance = useAppearance();

	data ||= getFlat(flatId);

	const [fetching, setFetching] = useState(false);
	const [reviews, setReviews] = useState([]);
	useEffect(() => {
		getFlatReviews(flatId)
			.then(setReviews)
			.catch(console.error);
	}, [flatId]);

	useEffect(() => setPeriod(null), []);

	return (
		<Panel id={ id }>
			<PanelHeader
				before={
					<PanelHeaderBack
						label="Назад"
						onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
					/>
				}
				separator={ false }
			>
				Квартира
			</PanelHeader>

			<PullToRefresh
				isFetching={ fetching }
				onRefresh={() => (setFetching(true), fetchFavorites(setFetching))}
			>
				<Group>
					{ (!!data.photos?.length) && (
						<Div>
							<div
								style={ {
									paddingTop: 96,
									backgroundImage: `url(https://maps.vk.com/api/staticmap/png?width=512&height=256&scale=2&zoom=11&latlon=${data.location.latitude},${data.location.longitude}&pins=${data.location.latitude},${data.location.longitude},red_pin&style=${appearance==='light'?'main':'dark'}&api_key=a4b32239b9ecd9ab61fb974a8027e42747152f97166b1320860b2a10c7858332)`,
									backgroundSize: 'cover',
									borderRadius: 8,
								} }
							>
								<Button stretched
									appearance="neutral"
									size="m"
									style={ {background: '#0D001C3D'} }
									onClick={() => routeNavigator.push(`/`)}
								>
									Смотреть на карте
								</Button>
							</div>
						</Div>
					) }

					<FlatCard
						data={ data }
						favorite={ favorites.includes(data.id) }
						fetchFavorites={ fetchFavorites }
						{ ...props }
					/>

					<Div style={ {paddingTop: 0} }>
						<Button stretched
							size="l"
							mode={ period===null?'primary':'secondary' }
							onClick={() => routeNavigator.showModal('periodselect')}
						>
							{ period===null?"Выбрать":"Изменить" } дату
						</Button>

						{ (period !== null) && <>
							<Spacing size={ 8 }/>

							<Button stretched
								size="l"
								onClick={() => (setUxrFlat(data), routeNavigator.showModal('paymentmethod'))}
							>
								Забронировать
							</Button>
						</> }
					</Div>

					<Spacing/>

					<Div>
						<Title>Описание</Title>

						<Spacing/>

						<Text>{ data.description }</Text>
					</Div>

					<Spacing/>

					<Div>
						<Title>Правила</Title>
					</Div>

					{ [
						[data.max_guests, <Icon24Users3Outline key={ null }/>, `Количество гостей: ${data.max_guests}`],
						[data.flags.permissions.childrenAllowed, <Icon24HorseToyOutline key={ null }/>, "Можно с детьми"],
						[data.flags.permissions.petsAllowed, <Icon24PawOutline key={ null }/>, "Можно с животными"],
						[data.flags.permissions.partiesAllowed, <Icon24CakeOutline key={ null }/>, "Можно вечеринки"],
					].map(([value, icon, text]) => (value) && (
						<MiniInfoCell
							key={ text }
							before={ icon }
						>
							{ text }
						</MiniInfoCell>
					)) }

					<Spacing/>

					<Div>
						<Title>Удобства</Title>
					</Div>

					{ [
						[data.flags.facilities.bath, <IconBath key={ null } width={ 24 } height={ 24 } fill="var(--vkui--color_icon_secondary)"/>, "Ванна"],
						[data.flags.facilities.fridge, <Icon20FreezeOutline key={ null } width={ 24 } height={ 24 }/>, "Холодильник"],
						[data.flags.facilities.kitchenSuite, <Icon24ChefHatOutline key={ null }/>, "Кухонный гарнитур"],
						[data.flags.facilities.washingMachine, <Icon24WasherOutline key={ null }/>, "Стиральная машина"],
						[data.flags.facilities.internet, <Icon56WifiOutline key={ null } width={ 24 } height={ 24 } fill="var(--vkui--color_icon_secondary)"/>, "Интернет"],
						[data.flags.facilities.tv, <Icon28TvOutline key={ null } width={ 24 } height={ 24 }/>, "Телевизор"],
						[data.flags.facilities.iron, <Icon24TshirtOutline key={ null }/>, "Утюг"],
					].map(([value, icon, text]) => (value) && (
						<MiniInfoCell
							key={ text }
							before={ icon }
						>
							{ text }
						</MiniInfoCell>
					)) }

					<Spacing/>

					<Div>
						<Title>О доме</Title>
					</Div>

					{ [
						["Год постройки", data.building_year],
						["Лифт", data.flags.elevator?'есть':'нет'],
						["Мусоропровод", data.flags.infrastructure.garbageChute?'есть':'нет'],
						["Парковка", data.flags.infrastructure.parking?'есть':'нет'],
					].map(([key, value]) => (value) && (
						<MiniInfoCell
							key={ key }
							after={ <span style={ {color: 'var(--vkui--color_text_primary)'} }>{ value }</span> }
							style={ {paddingTop: 0} }
						>
							{ key }:
						</MiniInfoCell>
					)) }

					<Spacing/>

					{ (!!reviews.length) && <>
						<Div>
							<Title>Отзывы</Title>
						</Div>

						<Group mode="card">
							{ reviews.map(({ name, date, avatar, text }, ii) => (<>
								<RichCell disabled
									key={ ii }
									before={ <Avatar src={ avatar } size={ 40 }/> }
									afterCaption={ date }
									caption={ undefined/*[true, true, true, true, false].map((i, ii) => (
										<span
											key={ ii }
											style={ i?{ color: 'gold' }:null }
										>
											{ i?'★':'☆' }
										</span>
									))*/ }
									bottom={ text }
								>
									{ name }
								</RichCell>

								<Spacing/>
							</>)) }
						</Group>
					</> }

					<Div>
						<Button stretched
							mode="secondary"
							size="l"
							onClick={() => routeNavigator.push(`/support/flat/${data.id}`)}
						>
							Задать вопрос
						</Button>
					</Div>
				</Group>
			</PullToRefresh>
		</Panel>
	);
};

Flat.propTypes = {
	id: PropTypes.string.isRequired,
	data: PropTypes.object,
	period: PropTypes.array, setPeriod: PropTypes.func.isRequired,
	setUxrFlat: PropTypes.func.isRequired,
	favorites: PropTypes.array.isRequired, fetchFavorites: PropTypes.func.isRequired,
};

export default Flat;

