import mmrgl from 'mmr-gl';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import 'mmr-gl/dist/mmr-gl.css';


mmrgl.accessToken = "a4b32239b9ecd9ab61fb974a8027e42747152f97166b1320860b2a10c7858332";  // prod


const Map = ({ flats, ...params }) => {
	const container = useRef();

	useEffect(() => {
		const map = new mmrgl.Map({
			container: container.current,
			...params,
		});

		flats.filter(({ location: { longitude, latitude } }) => (longitude && latitude)).sort((a, b) => (b.location.latitude - a.location.latitude)).forEach(({ id, rate, location: { longitude, latitude } }) => {
			new mmrgl.Marker()
				.setLngLat([longitude, latitude])
				.setPopup(
					new mmrgl.Popup({ closeButton: false })
						.setHTML(renderToStaticMarkup(
							<big>
								<a
									href={ `#/flat/${id}` }
									style={ {color: 'var(--vkui--color_text_link)', outline: 'none'} }
								>
									{ (rate.daily / 100).toLocaleString() } ₽/сут.
								</a>
							</big>
						))
				)
				.addTo(map);
		});

		return (() => (map) && map.remove());
	}, [flats, params]);

	return (<>
		<div
			ref={ container }
			style={ {position: 'absolute', left: 0, right: 0, top: 0, bottom: 0} }
		/>
	</>);
};

Map.propTypes = {
	flats: PropTypes.array.isRequired,
};

export default Map;
