import {
	Icon24Dismiss,
} from '@vkontakte/icons';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Button,
	FormItem,
	FormLayout,
	MiniInfoCell,
	ModalPage,
	ModalPageHeader,
	PanelHeaderButton,
	PanelHeaderClose,
	Platform,
	Radio,
	RadioGroup,
	ScreenSpinner,
	useAdaptivityConditionalRender,
	usePlatform,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React from 'react';


const PaymentMethodModal = ({
	id,
	amount,
}) => {
	const platform = usePlatform();
	const { sizeX } = useAdaptivityConditionalRender();

	const routeNavigator = useRouteNavigator();

	return (
		<ModalPage
			id={ id }
			hideCloseButton={ platform === Platform.IOS }
			header={
				<ModalPageHeader
					before={ (sizeX.compact && platform === Platform.ANDROID) && (
						<PanelHeaderClose
							className={ sizeX.compact.className }
							onClick={() => routeNavigator.hideModal()}
						/>
					) }
					after={ (sizeX.compact && platform === Platform.IOS) && (
						<PanelHeaderButton
							className={ sizeX.compact.className }
							onClick={() => routeNavigator.hideModal()}
						>
							<Icon24Dismiss/>
						</PanelHeaderButton>
					) }
				>
					Оплата
				</ModalPageHeader>
			}
		>
			<FormLayout>
				<FormItem top="Способ оплаты">
					<RadioGroup>
						<Radio defaultChecked
							name="method"
						>
							VK Pay
						</Radio>

						<Radio name="method">
							Оплата банковской картой
						</Radio>

						<Radio name="method">
							Оплата СБП
						</Radio>
					</RadioGroup>
				</FormItem>

				<FormItem>
					<MiniInfoCell
						after={ <s>{ amount.toLocaleString() } ₽</s> }
						style={ {paddingLeft: 0, paddingRight: 0} }
					>
						Итого без скидки
					</MiniInfoCell>

					<MiniInfoCell
						after={ <>{ (amount - 1000).toLocaleString() } ₽</> }
						mode="accent"
						style={ {paddingLeft: 0, paddingRight: 0} }
					>
						Сумма к оплате
					</MiniInfoCell>
				</FormItem>

				<FormItem>
					<Button stretched
						appearance="accent"
						size="l"
						onClick={() => {
							routeNavigator.hideModal();
							setTimeout(() => routeNavigator.showPopout(<ScreenSpinner size="large"/>), 200);
							setTimeout(() => routeNavigator.replace(`/uxr`), 2800);
						}}
					>
						Оплатить
					</Button>
				</FormItem>
			</FormLayout>
		</ModalPage>
	);
};

PaymentMethodModal.propTypes = {
	id: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
};

export default PaymentMethodModal;
