import {
	Icon28DeleteOutlineAndroid,
	Icon28EditOutline,
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { useActiveVkuiLocation, useFirstPageCheck, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	CellButton,
	Group,
	Header,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	SimpleCell,
	SubnavigationBar,
	SubnavigationButton,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


const Account = ({ id, fetchedUser }) => {
	const routeNavigator = useRouteNavigator();
	const { modal: activeModal } = useActiveVkuiLocation();
	const isFirstPage = useFirstPageCheck();

	const [profile, setProfile] = useState({});
	useEffect(() => {
		bridge.send('VKWebAppStorageGet', {
			keys: ['profile'],
		}).then((data) => {
			let { profile } = data.keys.reduce((res, { key, value }) => (res[key] = value, res), {});
			if (profile) profile = JSON.parse(profile);

			if (!profile) saveProfile(profile = { firstName: fetchedUser.first_name, lastName: fetchedUser.last_name });

			setProfile(profile);
		}).catch(console.error);
	}, [activeModal]);

	const saveProfile = (profile_) => {
		bridge.send('VKWebAppStorageSet', {
			key: 'profile',
			value: JSON.stringify(profile_ || profile),
		}).catch(console.error);
	};

	return (
		<Panel id={ id }>
			<PanelHeader
				before={
					<PanelHeaderBack
						label="Назад"
						onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
					/>
				}
			>
				Аккаунт
			</PanelHeader>

			<Group header={ <Header mode="secondary">Личная информация</Header> }>
				<SimpleCell
					indicator={ `${profile.firstName || ''} ${profile.lastName || ''}` }
				>
					Полное имя
				</SimpleCell>

				<SimpleCell indicator={ profile.email || "Не заполнено" }>
					E-mail
				</SimpleCell>

				<SimpleCell indicator={ profile.phone || "Не заполнено" }>
					Номер телефона
				</SimpleCell>

				<SubnavigationBar mode="fixed">
					<SubnavigationButton
						before={ <Icon28EditOutline/> }
						onClick={() => routeNavigator.push('./edit')}
					>
						Редактировать информацию
					</SubnavigationButton>
				</SubnavigationBar>
			</Group>

			<Group>
				<CellButton
					before={ <Icon28DeleteOutlineAndroid/> }
					mode="danger"
				>
					Удалить аккаунт PhoneDooring
				</CellButton>
			</Group>
		</Panel>
	);
};

Account.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
		photo_200: PropTypes.string,
	}),
};

export default Account;
