import {
	Icon20HeadphonesSupportOutline,
	Icon20UserOutline,
	Icon28PhoneOutline,
	Icon56QuestionOutline,
} from '@vkontakte/icons';
import { useActiveVkuiLocation, /*useEnableSwipeBack,*/ useFirstPageCheck, useParams, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Avatar,
	Div,
	FixedLayout,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	PanelHeaderButton,
	PanelHeaderContent,
	Placeholder,
	Separator,
	UsersStack,
	View,
	WriteBar,
	WriteBarIcon,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getFlat } from '../data';


const SupportView = ({
	id,
	messageText, setMessageText,
}) => {
	//useEnableSwipeBack();
	const routeNavigator = useRouteNavigator();
	const isFirstPage = useFirstPageCheck();
	const { panel: activePanel, panelsHistory } = useActiveVkuiLocation();
	const { flatId } = useParams();

	const [flat, setFlat] = useState(null);
	useEffect(() => setFlat(getFlat(flatId)), [flatId]);

	const [messages, /*setMessages*/] = useState([]);

	const [messagePadding, setMessagePadding] = useState(0);
	const messageBarRef = useRef();
	const updateMessagePadding = () => {
		const el = messageBarRef.current;
		if (el && el.offsetHeight !== messagePadding)
			setMessagePadding(el.offsetHeight);
	};

	return (
		<View
			id={ id }
			activePanel={ activePanel }
			history={ panelsHistory }
			onSwipeBack={() => routeNavigator.back()}
		>
			<Panel id="support">
				<PanelHeader
					before={ (flatId) && (
						<PanelHeaderBack
							label="Назад"
							onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
						/>
					) }
					after={
						<PanelHeaderButton aria-label="Позвонить в Поддержку">
							<Icon28PhoneOutline onClick={() => window.open("https://vk.com/call?id=801151321")}/>
						</PanelHeaderButton>
					}
				>
					<PanelHeaderContent before={
						<Avatar
							fallbackIcon={ <Icon20HeadphonesSupportOutline width={ 24 } height={ 24 }/> }
							size={ 36 }
						/>
					}>
						{ flatId?flat?`${flat.location.address.street}, ${flat.location.address.house}`:"":"Поддержка" }
					</PanelHeaderContent>
				</PanelHeader>

				{ (messages.length > 0) && messages.map(({ user_id, message }) => (
					<Div key={ [user_id, message] }>
						<UsersStack
							photos={ [user_id===null?<Icon20HeadphonesSupportOutline/>:<Icon20UserOutline/>] }
							direction={ user_id===null?'row':'row-reverse' }
						>
							{ message }
						</UsersStack>
					</Div>
				)) || (
					<Placeholder stretched
						icon={ <Icon56QuestionOutline/> }
					>
						Здесь вы можете задать любой вопрос
					</Placeholder>
				) }

				<div style={ {height: messagePadding} }/>

				<FixedLayout filled
					vertical="bottom"
				>
					<div ref={ messageBarRef }>
						<Separator wide/>

						<WriteBar
							before={ <WriteBarIcon
								aria-label="Прикрепить вложение"
								mode="attach"
							/> }
							after={ <WriteBarIcon
								aria-label="Отправить"
								mode="send"
								disabled={ !messageText }
							/> }
							placeholder="Сообщение"
							value={ messageText || '' }
							onChange={(e) => setMessageText(e.target.value)}
							onHeightChange={ updateMessagePadding }
						/>
					</div>
				</FixedLayout>
			</Panel>
		</View>
	);
};

SupportView.propTypes = {
	id: PropTypes.string.isRequired,
	messageText: PropTypes.string, setMessageText: PropTypes.func.isRequired,
};

export default SupportView;

