import {
	Icon28KeyOutline,
	Icon28LikeOutline,
	Icon28MessageOutline,
	Icon28SearchOutline,
	Icon28UserOutline,
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { useActiveVkuiLocation, usePopout, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Cell,
	Epic,
	Group,
	ModalRoot,
	Panel,
	PanelHeader,
	Platform,
	ScreenSpinner,
	SplitCol,
	SplitLayout,
	Tabbar,
	TabbarItem,
	useAdaptivityConditionalRender,
	usePlatform,
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import React, { useEffect, useState } from 'react';

import { routes } from '.';
import { getFavorites } from './data';
import PaymentMethodModal from './flats/modals/PaymentMethodModal';
import PeriodSelectModal from './flats/modals/PeriodSelectModal';
import AccountEditModal from './profile/modals/AccountEditModal';
import FavoritesView from './views/FavoritesView';
import KeysView from './views/KeysView';
import MenuView from './views/MenuView';
import SearchView from './views/SearchView';
import SupportView from './views/SupportView';
import UXRView from './views/UXRView';


const App = () => {
	const platform = usePlatform();
	const isVKCOM = platform === Platform.VKCOM;
	const { viewWidth } = useAdaptivityConditionalRender();

	const routeNavigator = useRouteNavigator();
	const { view: activeStory, modal: activeModal } = useActiveVkuiLocation();
	const activePopout = usePopout();

	const activeStoryStyles = {
		backgroundColor: 'var(--vkui--color_background_secondary)',
		borderRadius: 8,
	};

	const [searchFlat, setSearchFlat] = useState(null);
	const [favoritesFlat, setFavoritesFlat] = useState(null);
	const [favorites, setFavorites] = useState([]);
	const [period, setPeriod] = useState(null);

	const fetchFavorites = (setFetching, toggleId) => {
		setFetching?.(true);

		getFavorites()
			.then((favorites) => {
				if (toggleId > 0) favorites.push(toggleId);
				else if (toggleId < 0) favorites = favorites.filter((i) => (i != -toggleId));

				setFavorites(favorites);
				setFetching?.(false);
			})
			.catch((error) => (setFetching?.(false), console.error(error)));
	};
	useEffect(fetchFavorites, []);

	const [supportMessageText, setSupportMessageText] = useState(null);
	const [supportFlat, setSupportFlat] = useState(null);

	const [uxrFlat, setUxrFlat] = useState(null);

	const [fetchedUser, setUser] = useState(null);
	useEffect(() => {
		(isVKCOM) && routeNavigator.showPopout(<ScreenSpinner size="large"/>);

		bridge.send('VKWebAppGetUserInfo', {
		}).then((data) => {
			setUser(data);

			(isVKCOM) && routeNavigator.hidePopout();
		}).catch(console.error);
	}, []);

	useEffect(() => {
		bridge.send('VKWebAppStorageGet', {
			keys: ['notify'],  // TODO: ?vk_are_notifications_enabled=
		}).then((data) => {
			let { notify } = data.keys.reduce((res, { key, value }) => (res[key] = value, res), {});
			if (notify) notify = JSON.parse(notify);

			if (!notify?.result && (
				notify?.count <= 1 ||
				(notify?.count <= 3 && Date.now() - notify?.ts >= 60*60*24*7) ||
				(Date.now() - notify?.ts >= 60*60*24*30)
			))
				bridge.send('VKWebAppAllowNotifications')
					.then((data) => {
						bridge.send('VKWebAppStorageSet', {
							key: 'notify',
							value: JSON.stringify({
								...data,
								ts: Date.now(),
							}),
						}).catch(console.error);
					})
					.catch((error) => {
						bridge.send('VKWebAppStorageSet', {
							key: 'notify',
							value: JSON.stringify({
								result: false,
								error: error,
								count: (notify?.count || 0) + 1,
								ts: Date.now(),
							}),
						}).catch(console.error);

						console.error(error);
					});
		}).catch(console.error);
	}, []);

	useEffect(() => {
		bridge.send('VKWebAppStorageGet', {
			keys: ['onboarding'],
		}).then((data) => {
			let { onboarding } = data.keys.reduce((res, { key, value }) => (res[key] = value, res), {});
			if (onboarding) onboarding = JSON.parse(onboarding);

			(!['confirm', 'reject'].includes(onboarding?.action)) && bridge.send('VKWebAppShowSlidesSheet', {
				slides: [
					{
						title: "Привет!",
						subtitle: "Добро пожаловать в первый сервис полностью бесконтактной аренды в России!",
						media: {
							type: 'image',
							blob: 'data:image/png;base64,',
						},
					},
					{
						title: "Бесконтактная аренда",
						subtitle: "Просматривайте объявления только от проверенных арендодателей и не сомневайтесь в качестве жилья",
						media: {
							type: 'image',
							blob: 'data:image/png;base64,',
						},
					},
					{
						title: "Мгновенное заселение",
						subtitle: "Вам не придется связываться с арендодателям и ждать его ответа — после оплаты можете сразу же заселяться!",
						media: {
							type: 'image',
							blob: 'data:image/png;base64,',
						},
					},
					{
						title: "Надёжные замки",
						subtitle: "Вам не потребуется никаких физических ключей — открыть и закрыть квартиру можно с помощью приложения",
						media: {
							type: 'image',
							blob: 'data:image/png;base64,',
						},
					},
				],
			}).then((data) => {
				bridge.send('VKWebAppStorageSet', {
					key: 'onboarding',
					value: JSON.stringify(data),
				}).catch(console.error);
			}).catch(console.error);
		}).catch(console.error);
	}, []);

	return (
		<SplitLayout
			header={ !isVKCOM && <PanelHeader separator={ false }/> }
			modal={
				<ModalRoot
					activeModal={ activeModal }
					onClose={() => routeNavigator.hideModal()}
				>
					<PaymentMethodModal
						id="paymentmethod"
						amount={ uxrFlat?.rate?.daily * Math.max(((period?.[1] - period?.[0])/1000 / 86400), 1) / 100 }
					/>

					<PeriodSelectModal
						id="periodselect"
						setPeriod={ setPeriod }
					/>

					<AccountEditModal id="accountedit"/>
				</ModalRoot>
			}
			popout={ activePopout }
			style={ {justifyContent: 'center'} }
		>
			{ (isVKCOM || viewWidth.tabletPlus) && (
				<SplitCol fixed
					className={ !isVKCOM && viewWidth.tabletPlus.className }
					width={ 280 }
					maxWidth={ 280 }
				>
					<Panel>
						{ !isVKCOM && <PanelHeader/> }

						<Group>
							<Cell
								before={ <Icon28SearchOutline/> }
								style={ activeStory === 'search'?activeStoryStyles:undefined }
								onClick={() => routeNavigator.push(routes.search.map)}
							>
								Поиск
							</Cell>

							<Cell
								before={ <Icon28LikeOutline/> }
								style={ activeStory === 'favorites'?activeStoryStyles:undefined }
								onClick={() => routeNavigator.push(routes.favorites.list)}
							>
								Избранное
							</Cell>

							<Cell
								before={ <Icon28KeyOutline/> }
								style={ activeStory === 'keys'?activeStoryStyles:undefined }
								onClick={() => routeNavigator.push(routes.keys.keys)}
							>
								Ключи
							</Cell>

							<Cell
								before={ <Icon28MessageOutline/> }
								style={ activeStory === 'support'?activeStoryStyles:undefined }
								onClick={() => routeNavigator.push(routes.support.support)}
							>
								Поддержка
							</Cell>

							<Cell
								before={ <Icon28UserOutline/> }
								style={ activeStory === 'menu'?activeStoryStyles:undefined }
								onClick={() => routeNavigator.push(routes.menu.profile)}
							>
								Профиль
							</Cell>
						</Group>
					</Panel>
				</SplitCol>
			) }

			<SplitCol autoSpaced
				width="100%"
				maxWidth={ isVKCOM?630:1280 }
				stretchedOnMobile={ !isVKCOM }
			>
				<Epic
					activeStory={ activeStory }
					tabbar={ (!isVKCOM && viewWidth.tabletMinus) && (
						<Tabbar className={ viewWidth.tabletMinus.className }>
							<TabbarItem
								text="Поиск"
								selected={ activeStory === 'search' }
								onClick={() => routeNavigator.push(routes.search.map)}
							>
								<Icon28SearchOutline/>
							</TabbarItem>

							<TabbarItem
								text="Избранное"
								selected={ activeStory === 'favorites' }
								onClick={() => routeNavigator.push(routes.favorites.list)}
							>
								<Icon28LikeOutline/>
							</TabbarItem>

							<TabbarItem
								text="Ключи"
								selected={ activeStory === 'keys' }
								onClick={() => routeNavigator.push(routes.keys.keys)}
							>
								<Icon28KeyOutline/>
							</TabbarItem>

							<TabbarItem
								text="Поддержка"
								selected={ activeStory === 'support' }
								onClick={() => routeNavigator.push(routes.support.support)}
							>
								<Icon28MessageOutline/>
							</TabbarItem>

							<TabbarItem
								text="Профиль"
								selected={ activeStory === 'menu' }
								onClick={() => routeNavigator.push(routes.menu.profile)}
							>
								<Icon28UserOutline/>
							</TabbarItem>
						</Tabbar>
					) }
				>
					<SearchView
						id="search"
						flat={ searchFlat } setFlat={ setSearchFlat }
						period={ period } setPeriod={ setPeriod }
						setUxrFlat={ setUxrFlat }
						favorites={ favorites } fetchFavorites={ fetchFavorites }
					/>

					<FavoritesView
						id="favorites"
						flat={ favoritesFlat } setFlat={ setFavoritesFlat }
						period={ period } setPeriod={ setPeriod }
						setUxrFlat={ setUxrFlat }
						favorites={ favorites } fetchFavorites={ fetchFavorites }
					/>

					<KeysView id="keys"/>

					<SupportView
						id="support"
						messageText={ supportMessageText } setMessageText={ setSupportMessageText }
						flat={ supportFlat } setFlat={ setSupportFlat }
					/>

					<MenuView
						id="menu"
						fetchedUser={ fetchedUser }
					/>

					<UXRView
						id="uxr"
						flat={ uxrFlat }
					/>
				</Epic>
			</SplitCol>
		</SplitLayout>
	);
};

export default App;
