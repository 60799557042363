import {
	Group,
	Header,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	SimpleCell,
	Switch,
} from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';
import { useFirstPageCheck, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


const Notifications = ({ id }) => {
	const routeNavigator = useRouteNavigator();
	const isFirstPage = useFirstPageCheck();

	const [notifications, setNotifications] = useState({});
	useEffect(() => {
		bridge.send('VKWebAppStorageGet', {
			keys: ['notifications'],
		}).then((data) => {
			let { notifications } = data.keys.reduce((res, { key, value }) => (res[key] = value, res), {});
			if (notifications) notifications = JSON.parse(notifications);

			if (!notifications) saveNotifications(notifications = { openClose: true, payments: true, upcoming: true });

			setNotifications(notifications);
		}).catch(console.error);
	}, []);

	const saveNotifications = (notifications_) => {
		bridge.send('VKWebAppStorageSet', {
			key: 'notifications',
			value: JSON.stringify(notifications_ || notifications),
		}).catch(console.error);
	};

	return (
		<Panel id={ id }>
			<PanelHeader
				before={
					<PanelHeaderBack
						label="Назад"
						onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
					/>
				}
			>
				Уведомления
			</PanelHeader>

			<Group header={ <Header mode="secondary">Разрешения</Header> }>
				<SimpleCell
					Component="label"
					subtitle="Оповестит об успешном взаимодействии с квартирой"
					after={ <Switch
						checked={ notifications.openClose }
						onChange={e => (notifications.openClose = e.target.checked, setNotifications({...notifications}), saveNotifications())}
					/> }
				>
					Открытие/закрытие
				</SimpleCell>

				<SimpleCell
					Component="label"
					subtitle="Оповестит о снятии и зачислении средств"
					after={ <Switch
						checked={ notifications.payments }
						onChange={e => (notifications.payments = e.target.checked, setNotifications({...notifications}), saveNotifications())}
					/> }
				>
					Списание средств
				</SimpleCell>

				<SimpleCell
					Component="label"
					subtitle="Напомнит дату и время начала брони за сутки"
					after={ <Switch
						checked={ notifications.upcoming }
						onChange={e => (notifications.upcoming = e.target.checked, setNotifications({...notifications}), saveNotifications())}
					/> }
				>
					Напоминания о бронировании
				</SimpleCell>

				<SimpleCell
					Component="label"
					subtitle=""
					after={ <Switch
						checked={ notifications.weather }
						onChange={e => (notifications.weather = e.target.checked, setNotifications({...notifications}), saveNotifications())}
					/> }
				>
					Уведомления о погоде
				</SimpleCell>

				<SimpleCell
					Component="label"
					subtitle="Подберёт лучшие предложения"
					after={ <Switch
						checked={ notifications.recommendations }
						onChange={e => (notifications.recommendations = e.target.checked, setNotifications({...notifications}), saveNotifications())}
					/> }
				>
					Рекомендации
				</SimpleCell>
			</Group>
		</Panel>
	);
};

Notifications.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Notifications;
