import {
} from '@vkontakte/icons';
import { useActiveVkuiLocation, /*useEnableSwipeBack,*/ useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Button,
	Div,
	Group,
	Panel,
	PanelHeader,
	Placeholder,
	PullToRefresh,
	Spacing,
	Tappable,
	View,
} from '@vkontakte/vkui';
import { localeIncludes } from 'locale-includes';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import Flat from '../flats/Flat';
import FlatCard from '../components/FlatCard';
import SearchBar from '../components/SearchBar';

import { FLATS } from '../data';


const FavoritesView = ({
	id,
	flat, setFlat,
	period, setPeriod,
	setUxrFlat,
	favorites, fetchFavorites,
}) => {
	//useEnableSwipeBack();
	const routeNavigator = useRouteNavigator();
	const { panel: activePanel, panelsHistory } = useActiveVkuiLocation();

	const [flats, setFlats] = useState([]);
	const [fetching, setFetching] = useState(false);
	const [searchQuery, setSearchQuery] = useState(null);
	const [oldFavorites, setOldFavorites] = useState(favorites);

	useEffect(() => {
		setFlats(
			FLATS.filter(({ id, status, location: { address: { street, city }, transport } }) => (
				status === 'active' &&
				(
					favorites.includes(id) ||
					oldFavorites.includes(id)
				) &&
				(
					(!searchQuery) || (
						localeIncludes(street, searchQuery, { usage: 'search', sensitivity: 'base', ignorePunctuation: true }) ||
						localeIncludes(city, searchQuery, { usage: 'search', sensitivity: 'base', ignorePunctuation: true }) ||
						transport.filter(({ name }) => localeIncludes(name, searchQuery, { usage: 'search', sensitivity: 'base', ignorePunctuation: true })).length
					)
				)
			))
		);
	}, [favorites, searchQuery]);

	return (
		<View
			id={ id }
			activePanel={ activePanel }
			history={ panelsHistory }
			onSwipeBack={() => routeNavigator.back()}
		>
			<Panel id="list">
				<PanelHeader>PhoneDooring</PanelHeader>

				<SearchBar filled
					onChange={(e) => setSearchQuery(e.target.value.trim())}
					onFocus={(e) => e.target.placeholder = "Адрес или станция метро"}
					onBlur={(e) => e.target.placeholder = "Поиск"}
				/>

				<PullToRefresh
					isFetching={ fetching }
					onRefresh={() => (setFetching(true), fetchFavorites((fetching) => (setFetching(fetching), setOldFavorites(favorites))))}
				>
					<Group>
						{ flats.length?flats.map((i) => (
							<Fragment key={ i.id }>
								<Tappable onClick={() => (setFlat(i), routeNavigator.push(`./flat/${i.id}`))}>
									<FlatCard
										data={ i }
										favorite={ favorites.includes(i.id) }
										fetchFavorites={ fetchFavorites }
									/>

									<Div style={ {paddingTop: 0} }>
										<Button stretched
											size="l"
										>
											Забронировать
										</Button>
									</Div>
								</Tappable>

								<Spacing/>
							</Fragment>
						)):(
							<Placeholder stretched>
								{ !searchQuery?"Нет избранных квартир":"Не\u00A0найдено квартир, подходящих под\u00A0указанный запрос" }
							</Placeholder>
						) }
					</Group>
				</PullToRefresh>
			</Panel>

			<Flat
				id="flat"
				data={ flat }
				period={ period } setPeriod={ setPeriod }
				setUxrFlat={ setUxrFlat }
				favorites={ favorites } fetchFavorites={ fetchFavorites }
			/>
		</View>
	);
};

FavoritesView.propTypes = {
	id: PropTypes.string.isRequired,
	flat: PropTypes.object, setFlat: PropTypes.func.isRequired,
	period: PropTypes.array, setPeriod: PropTypes.func.isRequired,
	setUxrFlat: PropTypes.func.isRequired,
	favorites: PropTypes.array.isRequired, fetchFavorites: PropTypes.func.isRequired,
};

export default FavoritesView;



