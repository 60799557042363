import {
	Icon24Dismiss,
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Button,
	FormItem,
	FormLayout,
	Input,
	ModalPage,
	ModalPageHeader,
	PanelHeaderButton,
	PanelHeaderClose,
	Platform,
	useAdaptivityConditionalRender,
	usePlatform,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


const AccountEditModal = ({ id }) => {
	const platform = usePlatform();
	const { sizeX } = useAdaptivityConditionalRender();

	const routeNavigator = useRouteNavigator();

	const [profile, setProfile] = useState({});
	useEffect(() => {
		bridge.send('VKWebAppStorageGet', {
			keys: ['profile'],
		}).then((data) => {
			let { profile } = data.keys.reduce((res, { key, value }) => (res[key] = value, res), {});
			if (profile) profile = JSON.parse(profile);

			setProfile(profile || {});
		}).catch(console.error);
	}, []);

	const saveProfile = (callback) => {
		bridge.send('VKWebAppStorageSet', {
			key: 'profile',
			value: JSON.stringify(profile),
		}).then(callback).catch(console.error);
	};

	return (
		<ModalPage
			id={ id }
			hideCloseButton={ platform === Platform.IOS }
			header={
				<ModalPageHeader
					before={ (sizeX.compact && platform === Platform.ANDROID) && (
						<PanelHeaderClose
							className={ sizeX.compact.className }
							onClick={() => routeNavigator.hideModal()}
						/>
					) }
					after={ (sizeX.compact && platform === Platform.IOS) && (
						<PanelHeaderButton
							className={ sizeX.compact.className }
							onClick={() => routeNavigator.hideModal()}
						>
							<Icon24Dismiss/>
						</PanelHeaderButton>
					) }
				>
					Редактировать
				</ModalPageHeader>
			}
		>
			<FormLayout>
				<FormItem
					top="Имя"
					htmlFor="first-name"
				>
					<Input
						id="first-name"
						placeholder="Введите имя"
						value={ profile.firstName }
						onChange={ (e) => (profile.firstName = e.target.value, setProfile({...profile})) }
					/>
				</FormItem>

				<FormItem
					top="Фамилия"
					htmlFor="last-name"
				>
					<Input
						id="last-name"
						placeholder="Введите фамилию"
						value={ profile.lastName }
						onChange={ (e) => (profile.lastName = e.target.value, setProfile({...profile})) }
					/>
				</FormItem>

				<FormItem
					top="E-mail"
					bottomId="email-bottom"
					htmlFor="email"
				>
					<Input
						id="email"
						type="email"
						placeholder="Введите E-mail"
						aria-describedby="email-bottom"
						value={ profile.email }
						onChange={ (e) => (profile.email = e.target.value, setProfile({...profile})) }
					/>
				</FormItem>

				<FormItem
					top="Номер телефона"
					bottomId="phone-bottom"
					htmlFor="phone"
				>
					<Input
						id="phone"
						type="phone"
						placeholder="Введите номер телефона"
						aria-describedby="phone-bottom"
						value={ profile.phone }
						onChange={ (e) => (profile.phone = e.target.value, setProfile({...profile})) }
					/>
				</FormItem>

				<FormItem>
					<Button stretched
						appearance="accent"
						size="l"
						onClick={() => saveProfile(() => setTimeout(() => routeNavigator.hideModal(), 3000))}
					>
						Сохранить
					</Button>
				</FormItem>
			</FormLayout>
		</ModalPage>
	);
};

AccountEditModal.propTypes = {
	id: PropTypes.string.isRequired,
};

export default AccountEditModal;
