import {
	Icon16Add,
	Icon16Minus,
} from '@vkontakte/icons';
import {
	IconButton,
	Input,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React from 'react';


const SpinnerInput = ({
	min,
	max,
	value,
	onChange,
	...props
}) => {
	return (
		<Input
			before={
				<IconButton
					borderRadiusMode="inherit"
					disabled={ (min !== null) && (value <= min) }
					onClick={(e) => (e.target.value = (+(value || 0) - 1), (onChange && onChange(e)))}
				>
					<Icon16Minus color="var(--vkui--color_icon_secondary)"/>
				</IconButton>
			}
			type="number"
			align="center"
			value={ value }
			onChange={(e) => (e.target.value = +e.target.value>max?max:+e.target.value<min?min:+e.target.value, (onChange && onChange(e)))}
			after={
				<IconButton
					disabled={ (max !== null) && (value >= max) }
					onClick={(e) => (e.target.value = (+(value || 0) + 1), (onChange && onChange(e)))}
				>
					<Icon16Add/>
				</IconButton>
			}
			{ ...props }
		/>
	);
};

SpinnerInput.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.number,
	onChange: PropTypes.func,
};

export default SpinnerInput;
